.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px)
    }

    100% {
        transform: translateY(0px)
    }
}

.login-page-document {
  width: 92%;
  max-width: 600px;
  margin: 4%;
  text-align: center;
  padding: 70px;
  background-color: lightgray;
  font-size: 1.25rem;
  border-radius: 33px;
}

.authenticating-message {
  text-align: center;
  padding: 70px;
  font-size: 1.25rem;
}

/*Upload CSS*/
.upload-event {
    margin-top: 20px;
}

.upload-li {
    margin-left: -20px;
    width: 100%;
}

.upload-label {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    padding-left: 20px;
}

.event-log {
    list-style: none;
}

.hideItem {
    display: none;
}
